<template>
    <!-- Begin Banner Area -->
    <video-background
    src="images/banner/banner-section-bot.mp4"
    style="width: 100%;"
    class="hero-area hero-bg hero-style-1"
    >
        <div class="banner-area banner-style-4">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-sm-12 col-md-12">
                        <div class="banner-content">
                            <span class="text-purple">Industrial Sector</span>
                            <h2 class="text-blue">What can be solved with AI Riset.ai</h2>
                            <div class="row desktop" style="margin-top: 20px;">
                                <!-- <span @click="HealthcareClick" :class="{'btn purple-color': Healthcare === true,  'btn': Healthcare !== true}"><i class="icofont-heart-beat-alt"></i>Healthcare</span> -->
                                <span @click="RetailClick" :class="{'btn purple-color': Retail === true,  'btn': Retail !== true}"><i class="icofont-shopping-cart"></i>Retail Business</span>
                                <span @click="TransportationClick" :class="{'btn purple-color': Transportation === true,  'btn': Transportation !== true}"><i class="icofont-bus"></i>Transportation</span>
                                <span @click="IndustrialClick" :class="{'btn purple-color': Industrial === true,  'btn': Industrial !== true}"><i class="icofont-industries-4"></i>Industrial</span>
                                <span @click="HumanClick" :class="{'btn purple-color': Human === true,  'btn': Human !== true}"><i class="icofont-ui-user"></i>Human Surveillance</span>
                            </div>
                            <div class="row mobile" style="margin-top: 20px;">
                                <!-- <span @click="HealthcareClick" :class="{'btn purple-color': Healthcare === true,  'btn': Healthcare !== true}"><i class="icofont-heart-beat-alt"></i>Healthcare</span> -->
                                <span @click="RetailClick" :class="{'btn purple-color': Retail === true,  'btn': Retail !== true}"><i class="icofont-shopping-cart"></i>Retail Business</span>
                                <span @click="TransportationClick" :class="{'btn purple-color': Transportation === true,  'btn': Transportation !== true}"><i class="icofont-bus"></i>Transportation</span>
                                <span @click="IndustrialClick" :class="{'btn purple-color': Industrial === true,  'btn': Industrial !== true}"><i class="icofont-industries-4"></i>Industrial</span>
                                <span @click="HumanClick" :class="{'btn purple-color': Human === true,  'btn': Human !== true}"><i class="icofont-ui-user"></i>Human Surveillance</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="banner-img">
                            <div class="img">
                                <img :key="image" :src="image" alt="Banner">
                            </div>
                            <div class="about1_icon-box">
                                <div class="circle">
                                    <img src="images/banner/AI.png" style="padding-top: 30px; max-width: 80px;" alt="Banner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </video-background>
    <!-- Banner Area End Here -->
</template>
<script>
import VideoBackground from 'vue-responsive-video-background-player'
import image1 from '../../public/images/banner/banner-1.png'
import image2 from '../../public/images/banner/banner-2.png'
import image3 from '../../public/images/banner/banner-3.png'
import image4 from '../../public/images/banner/banner-4.png'
import image5 from '../../public/images/banner/banner-5.png'
export default {
  components: { VideoBackground },
  data () {
    return {
        Healthcare: false,
        Retail: false,
        Transportation: false,
        Industrial: false,
        Human: false,
        image: 'images/banner/banner-2.png',
        image1,
        image2,
        image3,
        image4,
        image5
    }
  },
  created () {
        this.loopImage();
    },
  methods: {
    loopImage () {
        setTimeout(() => {
            this.image = this.image2;
        }, 1000);
        setTimeout(() => {
            this.image = this.image3;
        }, 6000);
        setTimeout(() => {
            this.image = this.image4;
        }, 11000);
        setTimeout(() => {
            this.image = this.image5;
        }, 16000);
        setTimeout(() => {
            this.loopImage();
        }, 21000);
    },
    HealthcareClick: function() {
        this.Healthcare = true;
        this.Retail = false;
        this.Transportation = false;
        this.Industrial = false;
        this.Human = false;
        this.image = this.image1;
    },
    RetailClick: function() {
        setTimeout(() => {
            this.$router.push({ path: '/product-detail/cashier-less-store' })
        }, 500);
    },
    TransportationClick: function() {
        setTimeout(() => {
            // Set LocalStorage Category Transportation
            localStorage.setItem('category', 'Transportation AI');
            this.$router.push({ path: '/product' })
        }, 500);
    },
    IndustrialClick: function() {
        setTimeout(() => {
            this.$router.push({ path: '/product-detail/textile-defect-detection' })
        }, 500);
    },
    HumanClick: function() {
        setTimeout(() => {
            this.$router.push({ path: '/product-detail/face-recognition' })
        }, 500);
    },
  }
}
</script>
<style scoped>
@media (min-width: 1200px) {
    .hero-style-1.hero-bg {
        height: 680px;
    }
    

    .about1_icon-box {
        position: absolute;
        box-shadow: 0px 0px 0px #122b6136;
        top: 10%;
        left: 87%;
        transform: translateY(-57.5px);
    }

    .about1_icon-box .circle {
        height: 115px;
        width: 115px;
        background: #081b87;
        border-radius: 15px;
        position: absolute;
        text-align: center;
    }

    .about1_icon-box .circle:before {
        content: "";
        position: absolute;
    }
}

@media only screen and (max-width: 775px) {
    .hero-style-1.hero-bg {
        height: 1250px;
    }

    .about1_icon-box {
        position: absolute;
        box-shadow: 0px 0px 0px #122b6136;
        top: 44%;
        left: 78%;
        transform: translateY(-57.5px);
    }
    
    .about1_icon-box .circle {
        height: 115px;
        width: 115px;
        background: #081b87;
        border-radius: 15px;
        position: absolute;
        text-align: center;
    }
    
    .about1_icon-box .circle:before {
        content: "";
        position: absolute;
    }

    .banner-content {
        margin-bottom: 90px;
    }

    .banner-img .img img{
        margin-top: -30px !important;
    }
}

@media only screen and (min-width: 776px) and (max-width: 991px) {
    .hero-style-1.hero-bg {
        height: 1410px;
    }

    .about1_icon-box {
        position: absolute;
        box-shadow: 0px 0px 0px #122b6136;
        top: 44%;
        left: 60%;
        transform: translateY(-57.5px);
    }
    
    .about1_icon-box .circle {
        height: 115px;
        width: 115px;
        background: #081b87;
        border-radius: 15px;
        position: absolute;
        text-align: center;
    }
    
    .about1_icon-box .circle:before {
        content: "";
        position: absolute;
    }

    .banner-content {
        margin-bottom: 90px;
    }

    .banner-img .img img{
        margin-top: -30px !important;
    }
}

@media only screen and (max-width: 574px) {
    .hero-style-1.hero-bg {
        height: 1100px;
    }

    .about1_icon-box {
        position: absolute;
        box-shadow: 0px 0px 0px #122b6136;
        top: 54%;
        left: 74%;
        transform: translateY(-57.5px);
    }
    
    .about1_icon-box .circle {
        height: 95px;
        width: 95px;
        background: #081b87;
        border-radius: 15px;
        position: absolute;
        text-align: center;
    }

    .about1_icon-box .circle img{
        width: 50px;
    }
    
    .about1_icon-box .circle:before {
        content: "";
        position: absolute;
    }

    .banner-content {
        margin-bottom: 90px;
        align-content: center;
        text-align: center;
    }

    .banner-content .row {
        align-content: center;
        text-align: center;
    }

    .banner-content h2 {
        font-size: 38px;
    }

    .banner-img .img {
        text-align: center;
    }

    .banner-img .img img{
        margin-top: -30px !important;
        width: 90%;
    }

    .btn {
        background-color: transparent;
        text-align: left;
        color: #000000;
        font-family: 'Exo 2';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 24px !important;
        margin-top: 5px !important;
        width: 70% !important;
        height: 40px !important;
        line-height: 0px;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}

.text-purple {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    display: block;
    color: #CE097C;
}

.text-blue {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 1000;
    font-size: 48px;
    line-height: 58px;
    display: block;
    color: #25376F;
}

.btn.purple-color {
    background-color: #CE097C;
    color: #ffffff;
}

.btn {
    background-color: transparent;
    text-align: left;
    color: #000000;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    font-weight: 500;
    padding: 12px 24px;
    margin-top: 10px;
    width: 70%;
    height: 50px;
    line-height: 0px;
}

.btn i{
    font-size: 25px;
    margin-right: 15px;
}

.btn:hover {
    background-color: #CE097C;
    color: #ffffff;
}

.banner-img .img img{
    box-shadow: 15px -15px 5px #122b6136;
    border-bottom-left-radius: 60px;
    position: relative;
    margin-top: -80px;
}

.banner-content {
    padding: 0px;
    margin-top: -80px;
}
@media (min-width: 1500px) {
    .about1_icon-box {
        position: absolute;
        box-shadow: 0px 0px 0px #122b6136;
        top: 10%;
        left: 84%;
        transform: translateY(-57.5px);
    }

    .about1_icon-box .circle {
        height: 115px;
        width: 115px;
        background: #081b87;
        border-radius: 15px;
        position: absolute;
        text-align: center;
    }

    .about1_icon-box .circle:before {
        content: "";
        position: absolute;
    }
}
</style>
