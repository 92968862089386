<template>
    <div class="wrapper">

        <router-view></router-view>

        <!-- HeaderNav section -->
        <HeaderNav></HeaderNav>

        <!-- HeroSection section -->
        <HeroSection></HeroSection>

        <!-- Banner section -->
        <BannerThree></BannerThree>

        <!-- ScreenImage section -->
        <ScreenImage></ScreenImage>

        <!-- Latest News -->
        <LatestNews></LatestNews>

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'
import HeroSection from '@/components/HeroSection.vue'
import ScreenImage from '@/components/ScreenImage.vue'
import BannerThree from '@/components/BannerThree.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import FooterSection from '@/components/FooterSection.vue'
import LatestNews from '@/components/LatestNews.vue'

export default {
  components: {
    HeaderNav,
    HeroSection,
    ScreenImage,
    BannerTwo,
    FooterSection,
    LatestNews,
    BannerThree
  }
}
</script>
