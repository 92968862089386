<template>
    <!-- Begin Screen Area -->
    <video-background
    src="images/banner/banner-section.mp4"
    style="width: 100%;"
    object-fit="cover"
    class="hero-area hero-bg hero-style-1"
    >
      <div class="container screen-container">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm-12">
            <div class="section-area">
              <h2 class="section-desc">Blog</h2>
              <p class="section-title">The Latest from Riset.ai</p>
            </div>
          </div>
          <div class="col-lg-10 col-md-10 col-sm-12 desktop">
            <swiper class="swiper-container screen-slider"
            :breakpoints="swiperOptions.breakpoints"
            :navigation="swiperOptions.navigation"
            @swiper="onSwiper"
            >
              <swiper-slide  v-for="(slider, sliders) in sliders" :key="sliders" class="testimonial-item-2">
                <div class="testimonial-content">
                  <router-link :to="`/blog-detail/${slider.slug}`" class="">
                      <img :src="`${slider.image ? imageUrl + slider.image : 'images/news/news-3.png'}`" style="width: 100%; height: 275px; object-fit: cover;" alt="Image News">
                  </router-link>
                  <!-- <img :src="`${slider.image ? imageUrl + slider.image : 'images/news/news-3.png'}`" :alt="slider.alt"> -->
                  <div class="testimonial-user-info">
                    <div class="user-content">
                      <h2 class="user-title">{{ slider.title }}</h2>
                      <p>{{ moment(slider.created_at).format('DD MMMM YYYY') }}</p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mobile">
              <div class="row feature-slider-2 mt-n30">
                  <div v-for="(order, index) in sliders" :key="index" class="col-lg-6 col-sm-12 mt-30">
                      <router-link :to="`/blog-detail/${order.slug}`" class="feature-icon">
                          <img :src="`${order.image ? imageUrl + order.image : 'images/news/news-3.png'}`" style="width: 100%; height: 275px; object-fit: cover;" alt="Image News">
                      </router-link>
                      <!-- <div class="feature-icon">
                          <img :src="`${order.image ? imageUrl + order.image : 'images/news/news-3.png'}`" alt="Image News">
                      </div> -->
                      <div class="feature-content">
                          <h5 class="title">{{order.title}}</h5>
                          <p>{{ moment(order.created_at).format('DD MMMM YYYY') }}</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="btn-wrap col-lg-10 col-sm-12" style="margin-top: 20px;">
              <router-link class="btn purple-color blackcurrant-hover" to="/blog">See More</router-link>
          </div>
        </div>
      </div>  
    </video-background>
    <!-- Screen Area End Here -->
</template>

<script>
import SwiperCore from 'swiper/core'
import { Navigation } from 'swiper';
import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue'
import VideoBackground from 'vue-responsive-video-background-player'
SwiperCore.use([Navigation])

export default {
  components: {
    Swiper,
    SwiperSlide,
    VideoBackground
  },

  data () {
    return {
      bannerBg: 'images/migrate/bg/1-2-1920x1757.png',
      imageUrl: 'https://dev.api.the-netwerk.com/storage/app/',
      moment,
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          480: {
            slidesPerView: 1
          },
          992: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        },
        navigation: {
          nextEl: '.screen-button-next',
          prevEl: '.screen-button-prev'
        }
      },
      blog: [],
    }
  },
  mounted () {
      this.getData()
  },
  computed: {
    sliders () {
        return this.blog.slice(0, 3)
    },
  },
  methods: {
      onSwiper (swiper) {
          this.swiper = swiper
      },
      getData() {
          this.axios.get(`https://dev.api.the-netwerk.com/api/RisetAI/H1wWlOLOSzW6F4HjLC1K/blog`).then(res => {
              this.blog = res.data.data
          })
      }
  }

}

</script>
<style scoped>
.hero-style-1.hero-bg {
    height: 650px;
}
.screen-area {
  padding-top: 0px;
}
.testimonial-content img {
  width: 100%;
  border-radius: 16px;
}
.testimonial-content a :hover {
  opacity: 0.7;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.testimonial-content :hover {
  cursor: pointer;
}
.section-area {
    margin-bottom: 50px;
}
.section-desc {
  color: #8D1245;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 42px;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-top: 40px;
}
.user-title {
  text-align: left;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
}
.screen-container {
  align-items: center;
  text-align: center;
  margin-bottom: 60px;
  margin-left: 10%;
  margin-right: 10%;
}
.section-title {
  color: #25376F;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.03em;
}
.testimonial-item-2 {
  display: flex;
}
.btn.purple-color {
  background-color: #CE097C;
  color: #ffffff;
  font-family: "Exo 2";
  font-size: 18px;
  font-weight: 500;
  border-radius: 50px;
  padding: 25px 30px;
  width: 210px;
  height: 55px;
  line-height: 0px;
}
.testimonial-user-info {
  margin-top: 15px;
}
.testimonial-user-info .user-content {
  margin-left: 0px;
  text-align: left;
}

.mobile {
  display: none;
}

.feature-icon :hover{
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .hero-style-1.hero-bg {
    height: 1950px;
    justify-content: center;
    align-items: center;
  }

  .desktop {
    display: none;
  }
  
  .mobile {
    display: block;
  }

  .btn.purple-color {
    margin-top: 50px;
    font-size: 24px;
    font-weight: 500;
    border-radius: 50px;
    padding: 28px 30px;
    width: 240px;
    height: 65px;
  }

  .feature-icon img{
    width: 100%;
  }
}

@media (max-width: 595px) {
  .screen-container {
    max-width: 80%;
  }

  .hero-style-1.hero-bg {
    height: 1475px;
    justify-content: center;
    align-items: center;
  }

  .desktop {
    display: none;
  }
  
  .mobile {
    display: block;
  }

  .feature-content .title {
    text-align: center;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 600;
    font-size: 24px !important;
  }
}
</style>
