<template>
  <!-- Begin Hero Area -->
  <div class="hero-area hero-bg hero-style-1">
    <video-background src="images/hero/Section-Hero-1-loop.mp4" object-fit="cover" style="width: 100%" class="hero-item desktop">
      <div class="hero-item">
        <div class="hero-content">
          <h1 class="title" style="color: white; font-family: 'Exo 2'">Aligning AI systems with human intent.</h1>
          <p class="desc" style="color: white; font-family: 'Exo 2'">We're creating a new form of artificial intelligence algorithm, most of the people will call it life.</p>
          <div class="btn-wrap">
            <router-link class="btn purple-color sapphire-hover" to="/about-us">Learn More</router-link>
          </div>
        </div>
        <!-- <div class="single-img">
          <img src="images/hero/hero-model 1.png" style="" alt="Hero Image" />
        </div> -->
      </div>
    </video-background>
    <video-background src="images/hero/Section-Hero-1-loop-responsive.mp4" object-fit="cover" style="width: 100%" class="hero-item mobile">
      <div class="hero-item">
        <div class="hero-content">
          <h1 class="title" style="color: white; font-family: 'Exo 2'">Aligning AI systems with human intent.</h1>
          <p class="desc" style="color: white; font-family: 'Exo 2'">We're creating a new form of artificial intelligence algorithm, most of the people will call it life.</p>
          <div class="btn-wrap">
            <router-link class="btn purple-color sapphire-hover" to="/about-us">Learn More</router-link>
          </div>
        </div>
        <!-- <div class="single-img">
          <img src="images/hero/hero-model 1.png" style="" alt="Hero Image" />
        </div> -->
      </div>
    </video-background>
    <div class="display-flex row" id="partner">
      <div class="col-lg-10 col-md-12">
        <div class="text-center">
          <h6 class="text-center mt-10" style="font-family: 'Exo 2'; font-weight: 500">Trusted by Many Companies</h6>
          <img src="images/hero/nvidia-.png" alt="Hero Image">
          <img src="images/hero/biznet- 1.png" alt="Hero Image">
          <img src="images/hero/Hikvision-.png" alt="Hero Image">
          <img src="images/hero/hero-mcd.png" alt="Hero Image">
          <img src="images/hero/hero-paramount.png" alt="Hero Image">
          <img src="images/hero/loket-.png" alt="Hero Image">
          <img src="images/hero/pawoon-.png" alt="Hero Image">
        </div>
      </div>
    </div>
  </div>
  <video-background src="images/banner/banner-section.mp4" style="width: 100%" object-fit="cover" class="hero-area hero-bg hero-style-1 hero-width desktop">
    <div class="banner-area banner-style-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 mobile">
            <div class="banner-content" style="padding-top: 0px">
              <!-- <span class="text-purple">About Us</span> -->
              <h2 class="text-blue">What is Riset.AI</h2>
              <p class="banner-desc">
                PT Riset Kecerdasan Buatan (“RKB”) is a company that focuses on the research and development of AI solutions (Computer Vision) for a wide range of industries that are adapting to Indonesia’s rapidly digitalizing economy.
                RKB’s solutions have garnered interest in both the public and private sector.
              </p>
              <div class="btn-wrap" style="margin-top: 20px; margin-bottom: 30px">
                <router-link class="btn purple-color blackcurrant-hover" to="/about-us">Read More</router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="banner-img">
              <div class="img">
                <img src="images/banner/banner.png" alt="Banner" />
              </div>
              <div class="about1_icon-box">
                <div class="circle">
                  <img src="images/banner/riset-white.png" style="padding-top: 18px; max-width: 80px" alt="Banner" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 desktop">
            <div class="banner-content" style="padding-top: 0px">
              <!-- <span class="text-purple">About Us</span> -->
              <h2 class="text-blue">What is Riset.AI</h2>
              <p class="banner-desc" style="font-size: 18px; font-family: 'Exo 2'; line-height: 1.8; font-weight: 400; margin-bottom: 0px">
                PT Riset Kecerdasan Buatan (“RKB”) is a company that focuses on the research and development of AI solutions (Computer Vision) for a wide range of industries that are adapting to Indonesia’s rapidly digitalizing economy.
                RKB’s solutions have garnered interest in both the public and private sector.
              </p>
              <div class="btn-wrap" style="margin-top: 20px">
                <router-link class="btn purple-color blackcurrant-hover" to="/about-us">Read More</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </video-background>
  <video-background src="images/banner/banner-section.mp4" style="width: 100%" object-fit="cover" class="hero-area hero-bg hero-style-1 hero-width mobile">
    <div class="banner-area banner-style-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 mobile">
            <div class="banner-content" style="padding-top: 0px; margin-top: 0px;">
              <!-- <span class="text-purple">About Us</span> -->
              <h2 class="text-blue">What is Riset.AI</h2>
              <p class="banner-desc">
                PT Riset Kecerdasan Buatan (“RKB”) is a company that focuses on the research and development of AI solutions (Computer Vision) for a wide range of industries that are adapting to Indonesia’s rapidly digitalizing economy.
                RKB’s solutions have garnered interest in both the public and private sector.
              </p>
              <div class="btn-wrap" style="margin-top: 20px; margin-bottom: 30px">
                <router-link class="btn purple-color blackcurrant-hover" to="/about-us">Read More</router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="banner-img">
              <div class="img">
                <img src="images/banner/banner.png" alt="Banner" />
              </div>
              <div class="about1_icon-box">
                <div class="circle">
                  <img src="images/banner/riset-white.png" style="padding-top: 18px; max-width: 80px" alt="Banner" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 desktop">
            <div class="banner-content" style="padding-top: 0px">
              <!-- <span class="text-purple">About Us</span> -->
              <h2 class="text-blue">What is Riset.AI</h2>
              <p class="banner-desc" style="font-size: 18px; font-family: 'Exo 2'; line-height: 1.8; font-weight: 400; margin-bottom: 0px">
                PT Riset Kecerdasan Buatan (“RKB”) is a company that focuses on the research and development of AI solutions (Computer Vision) for a wide range of industries that are adapting to Indonesia’s rapidly digitalizing economy.
                RKB’s solutions have garnered interest in both the public and private sector.
              </p>
              <div class="btn-wrap" style="margin-top: 20px">
                <router-link class="btn purple-color blackcurrant-hover" to="/about-us">Read More</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </video-background>
  <!-- Hero Area End Here -->
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";
export default {
  components: { VideoBackground },
  data() {
    return {
      heroBg: "images/hero/bg/background-hero.png",
      heroBg2: "images/hero/bg/background-hero2.png",
    };
  },
};
</script>
<style scoped>
.hero-style-1.hero-bg {
  height: 710px;
}
.text-purple {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  display: block;
  color: #ce097c;
}

.text-blue {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 1000;
  font-size: 48px;
  line-height: 58px;
  display: block;
  color: #25376f;
}

.btn.purple-color {
  background-color: #ce097c;
  color: #ffffff;
  font-family: "Exo 2";
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  padding: 25px 30px;
  width: 210px;
  height: 55px;
  line-height: 0px;
}

.hero-style-1 .hero-item .single-img .img-2 {
  position: absolute;
  mix-blend-mode: screen;
  width: 50%;
  margin-top: 60px;
}

.hero-style-1 .hero-item .single-img {
  width: 50%;
  margin-top: 60px;
}

.hero-style-1 .hero-content .title {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 84px;
}

.banner-content p {
  font-size: 20px;
  font-family: 'Exo 2';
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 0px
}

.mobile {
  display: none;
}

/* Media Android */
@media only screen and (max-width: 991px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .banner-desc {
    font-size: 18px;
  }

  .hero-style-1.hero-bg {
    width: 100%;
    height: 1100px;
  }
  .hero-width {
    width: 100%;
    height: 1200px !important;
  }
  .desktop {
    display: none;
  }
  .text-purple {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    display: block;
    color: #ce097c;
  }

  .text-blue {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 1000;
    font-size: 48px;
    line-height: 58px;
    display: block;
    color: #25376f;
  }

  .hero-style-1 .hero-content .title {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 90px;
    /* or 150% */

    text-align: center;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .hero-style-1 .hero-content .desc {
    font-family: "Exo 2";
    font-style: normal;
    font-size: 20px;
    line-height: 35px;
    /* or 175% */

    text-align: center;
    max-width: 100%;
  }

  .hero-style-1 .hero-content {
    text-align: center;
    align-items: center;
  }

  .btn.purple-color {
    background-color: #ce097c;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 16px;
    font-weight: 500;
    border-radius: 50px;
    padding: 24px 20px;
    width: 160px;
    height: 55px;
    line-height: 0px;
  }

  /* .single-img img {
        align-items: center;
        margin-top: -45px;
        width: 900px;
        height: auto;
    } */

  .hero-style-1 .hero-item .single-img {
    align-items: center;
    margin-top: 25px;
    text-align: center;
    width: 80%;
    height: auto;
    margin-left: 5%;
    margin-right: 5%;
  }

  /* .hero-style-1 .hero-item .single-img .img-2{
        width: 90%;
        position: absolute;
        mix-blend-mode: screen;
        top: -10px;
        left: 53px;
    } */

  .display-flex {
    height: 0px;
    width: 90%;
    display: flex;
    margin-top: -80px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .display-flex div {
    text-align: center;
    background-color: white;
    padding: 12px 38px;
    z-index: 10;
    border-radius: 12px;
  }

  .display-flex img {
    width: 30%;
    padding: 5px 5px;
  }

  .banner-img .img img {
    box-shadow: -12px 12px 5px #122b6136;
    border-top-right-radius: 60px;
    position: relative;
  }

  .banner-content {
    padding: 0px;
    margin-top: 30px;
  }

  .about1_icon-box {
    position: absolute;
    box-shadow: 0px 0px 0px #122b6136;
    top: 95%;
    left: 20px;
    transform: translateY(-57.5px);
  }

  .about1_icon-box .circle {
    height: 115px;
    width: 115px;
    background: #081b87;
    border-radius: 15px;
    position: absolute;
    text-align: center;
  }

  .about1_icon-box .circle:before {
    content: "";
    position: absolute;
  }
}

@media (min-width: 1200px) {
  .hero-style-1 .hero-content {
    margin-left: 60px;
  }

  .mobile {
    display: none;
  }

  .display-flex {
    height: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: -80px;
  }

  .display-flex div {
    background-color: white;
    padding: 12px 38px;
    justify-content: space-around;
    z-index: 10;
    border-radius: 12px;
  }

  .display-flex img {
    padding: 15px 15px;
  }

  .banner-img .img img {
    box-shadow: -15px 15px 5px #122b6136;
    border-top-right-radius: 60px;
    position: relative;
  }

  .banner-content {
    padding: 0px;
    margin-top: 100px;
  }

  .about1_icon-box {
    position: absolute;
    box-shadow: 0px 0px 0px #122b6136;
    top: 88%;
    left: 50px;
    transform: translateY(-57.5px);
  }

  .about1_icon-box .circle {
    height: 115px;
    width: 115px;
    background: #081b87;
    border-radius: 15px;
    position: absolute;
    text-align: center;
  }

  .about1_icon-box .circle:before {
    content: "";
    position: absolute;
  }
}
@media (min-width: 1500px) {
  .about1_icon-box {
    position: absolute;
    box-shadow: 0px 0px 0px #122b6136;
    top: 88%;
    left: 120px;
    transform: translateY(-57.5px);
  }

  .about1_icon-box .circle {
    height: 115px;
    width: 115px;
    background: #081b87;
    border-radius: 15px;
    position: absolute;
    text-align: center;
  }

  .about1_icon-box .circle:before {
    content: "";
    position: absolute;
  }

  .display-flex div {
    background-color: white;
    padding: 12px 54px;
    justify-content: space-around;
    z-index: 10;
    border-radius: 12px;
  }
}
@media only screen and (max-width: 510px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
  .hero-style-1.hero-bg {
    width: 100%;
    height: 1100px;
  }
  .hero-width {
    width: 100%;
    height: 1000px !important;
  }

  .hero-style-1 .hero-content {
    text-align: center;
    align-items: center;
  }

  .btn.purple-color {
    background-color: #ce097c;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 16px;
    font-weight: 500;
    border-radius: 50px;
    padding: 24px 20px;
    width: 160px;
    height: 55px;
    line-height: 0px;
  }

  .banner-img .img img {
    box-shadow: -12px 12px 5px #122b6136;
    border-top-right-radius: 60px;
    position: relative;
    width: 80%;
  }

  .banner-img .img{
    text-align: center;
  }

  .banner-content h2{
    text-align: center;
  }

  .banner-content p {
    font-size: 20px;
    line-height: 30px;
  }

  .about1_icon-box {
    position: absolute;
    box-shadow: 0px 0px 0px #122b6136;
    top: 90% !important;
    left: 10px;
    transform: translateY(-57.5px);
  }

  .about1_icon-box .circle {
    height: 95px;
    width: 95px;
    background: #081b87;
    border-radius: 15px;
    position: absolute;
    text-align: center;
  }

  .about1_icon-box .circle img{
    width: 60px;
  }

  .about1_icon-box .circle:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
  }

  .hero-style-1 .hero-item .single-img {
    align-items: center;
    margin-top: 40px;
    text-align: center;
    width: 80%;
    height: auto;
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (min-width: 411px) and (max-width: 574px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .banner-img .img{
    text-align: center;
  }

  .banner-img .img img {
    box-shadow: -12px 12px 5px #122b6136;
    border-top-right-radius: 60px;
    position: relative;
    width: 80%;
  }

  .about1_icon-box {
    position: absolute;
    box-shadow: 0px 0px 0px #122b6136;
    top: 86%;
    left: 12px;
    transform: translateY(-57.5px);
  }

  .about1_icon-box .circle {
    height: 95px;
    width: 95px;
    background: #081b87;
    border-radius: 15px;
    position: absolute;
    text-align: center;
  }

  .about1_icon-box .circle img{
    width: 60px;
  }

  .about1_icon-box .circle:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
  }
}
</style>
