<template>
    <!-- Begin Screen Area -->
    <video-background
    src="images/screen/background-loop.mp4"
    style="width: 100%;"
    class="hero-area hero-bg hero-style-1"
    >
      <div class="container screen-container">
            <div class="row">
                <div class="col-12">
                    <div class="section-area">
                        <h2 class="section-desc">Our Products</h2>
                        <p class="section-title">Our technology and algorithms are implemented on new and exciting applications</p>
                    </div>
                    <div class="row">
                      <div class="col-1" style="display: flex;align-items: center;justify-content: center;">
                        <div class="screen-button-prev swipper-arrow desktop">
                            <i class="icofont-curved-left"></i>
                        </div>
                      </div>
                      <div class="col-10">
                        <swiper class="swiper-container screen-slider"
                        :options="swiperOptions"
                        :breakpoints="swiperOptions.breakpoints"
                        :pagination="{ clickable: true }"
                        :navigation="swiperOptions.navigation"
                        @swiper="onSwiper"
                        >
                          <swiper-slide  v-for="(slider, index) in product" :key="index" class="testimonial-item-2">
                            <div class="testimonial-content">
                                <router-link :to="`/product-detail/${slider.slug}`" class="">
                                    <img :src="`${slider.blogImage}`" style="width: 100%; height: 300px;" alt="Image Source">
                                </router-link>
                                <div class="testimonial-user-info">
                                    <div class="user-content">
                                        <div class="btn-wrap">
                                            <router-link :class="slider.class" :to="`/product-detail/${slider.slug}`">{{ slider.category }}</router-link>
                                        </div>
                                        <div class="slider-title">
                                          <h2>{{ slider.title }}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </swiper-slide>
                        </swiper>
                      </div>
                      <div class="col-1" style="display: flex;align-items: center;justify-content: center;">
                        <div class="screen-button-next swipper-arrow desktop">
                          <i class="icofont-curved-right"></i>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>  
    </video-background>
    <!-- Screen Area End Here -->
</template>

<script>
import SwiperCore from 'swiper/core'
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue'
import VideoBackground from 'vue-responsive-video-background-player'
import products from '../data/product.json'
SwiperCore.use([Navigation])

export default {
  components: {
    Swiper,
    SwiperSlide,
    VideoBackground
  },

  data () {
    return {
      bannerBg: 'images/migrate/bg/1-2-1920x1757.png',
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        },
        on: {
          slideChange: function () {
              if (this.isEnd) {
                  this.loopDestroy();
                  this.loopCreate();
              }
              if (this.isBeginning) {
                  this.loopDestroy();
                  this.loopCreate();
              }
          }
        },
        navigation: {
          nextEl: '.screen-button-next',
          prevEl: '.screen-button-prev'
        },
      },
      products,
    }
  },

  computed: {
    product () {
      return this.products.products
    }
  },

  methods: {
    onSwiper (swiper) {
      this.swiper = swiper
    }
  }

}

</script>
<style scoped>
.hero-style-1.hero-bg {
    height: 850px;
}

.slider-title h2{
  width: 100%;
  font-family: 'Exo 2' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-top: 12px;
}

@media only screen and (max-width: 991px) {
    .hero-style-1.hero-bg {
        height: 1000px;
    }

    .testimonial-item-2 {
      background-color: transparent !important;
      border-radius: 30px;
      height: 460px;
      justify-content: space-around;
      display: flex;
    }

    .testimonial-content {
      background-color: #ffffff;
      border-radius: 30px;
    }

    .testimonial-content img {
      object-fit: cover;
      width: 100%;
      max-height: 300px !important;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .slider-title h2{
      width: 80%;
    }
}

@media only screen and (max-width: 574px) {
    .hero-style-1.hero-bg {
        height: 925px;
    }

    .desktop {
      display: none;
    }

    .screen-button-prev i{
      border: 0px;
      width: 40px !important;
      height: 40px !important;
      line-height: 30px;
      color: #FFFFFF;
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
    }
    .screen-button-next i{
      border: 0px;
      width: 40px !important;
      height: 40px !important;
      line-height: 30px;
      color: #FFFFFF;
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
    }

    .section-area p,
    .section-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 54px;
    }
}

.screen-area {
  padding-top: 0px;
}
.testimonial-content img {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.section-desc {
  color: #ffffff;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 42px;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-top: 40px;
}
.section-area {
  align-items: center;
  text-align: center;
  margin-bottom: 60px;
  margin-left: 10%;
  margin-right: 10%;
}
.section-title {
  color: #ffffff;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.03em;
}
.testimonial-item-2 {
  background-color: #ffffff;
  border-radius: 16px;
  height: 460px;
  display: flex;
}
.btn.pink-color {
  background-color: #CE097C;
  color: #ffffff;
  font-family: "Exo 2";
  font-size: 12px;
  border-radius: 6px;
  padding: 12px 8px;
  line-height: 0px;
}
.btn.blue-color {
  background-color: #27376C;
  color: #ffffff;
  font-family: "Exo 2";
  font-size: 12px;
  border-radius: 6px;
  padding: 12px 8px;
  line-height: 0px;
}
.btn.blue-dark-color { 
    background-color: #1F6B82;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    border-radius: 6px;
    padding: 12px 8px;
    line-height: 0px;
}
.btn.brown-color {
  background-color: #5A051A;
  color: #ffffff;
  font-family: "Exo 2";
  font-size: 12px;
  border-radius: 6px;
  padding: 12px 8px;
  line-height: 0px;
}
.testimonial-user-info {
  margin-top: 15px;
}
.screen-button-prev i{
  border: 0px;
  width: 51px;
  height: 51px;
  line-height: 50px;
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.screen-button-next i{
  border: 0px;
  width: 51px;
  height: 51px;
  line-height: 50px;
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
</style>
